export interface IApiOptions {
  method: "POST" | "GET" | "PATCH" | "DELETE";
  headers: {};
  body?: string | FormData;
}

export async function UseLogin(email: string, password: string) {
  const options: IApiOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: {
        type: "UserSession",
        attributes: {
          email,
          password,
        },
      },
    }),
  };
  const request_result = await fetch(
    "https://api.dev.floralogistics.nl/v1/sessions/",
    options
  );

  return request_result.json();
}
