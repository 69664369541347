import { BASE_URL } from "../settings/env";
import fetchData, { IApiOptions } from "./fetchData";

export async function UseSession(session: string) {
  const options: IApiOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session,
    },
  };

  return await fetchData(BASE_URL + "v1/sessions/" + session + "/", options);
}
