export interface IApiOptions {
  method: "POST" | "GET" | "PATCH" | "DELETE";
  headers: {};
  body?: string | FormData;
}

export interface IErrorResponse {
  errors: IErrorResponseErrorContent[];
}

export interface IErrorResponseErrorContent {
  status: string;
  code: string;
  meta: {
    key: string;
  };
}

export interface IError {
  message: string;
  code: number;
  error_response?: IErrorResponse;
}

export default async function fetchData(
  url: string,
  options: IApiOptions
): Promise<any> {
  try {
    const response: Response = await fetch(url, options);
    if (!response.ok) {
      throw response;
    }

    if (response.status === 204) {
      return null;
    }

    return await response.json();
  } catch (err: any) {
    const errorResponse: IErrorResponse = await err.json();

    const error: IError = {
      message: err.statusText,
      code: err.status,
      error_response: errorResponse,
    };

    throw error;
  }
}
