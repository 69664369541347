import React from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { AddGame } from "../addGame/addGame";
import { GameOverview } from "../gameOverview/gameOverview";
import { UseSession } from "../hooks/useSession";
import { RatingOverview } from "../ratingOverview/ratingOverview";
import "./app.scss";

export const App: React.FC = () => {
  
  React.useEffect(() => {
    checkSession();
  }, []);

  return (
    <BrowserRouter>
      <div className="app-container">
        <div className="menu">
          <div className="menu-header">Tabel tennis</div>
          <div className="menu-items">
            <Link to={"/"}>Home</Link>
            <Link to={"/games"}>Games</Link>
          </div>
        </div>
        <div className="content">
          <Routes>
            <Route element={<RatingOverview />} path="/" />
            <Route element={<AddGame />} path="/add-game" />
            <Route element={<GameOverview />} path="/games/" />
            <Route element={<GameOverview />} path="/games/:id" />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );

  async function checkSession() {
    const session = localStorage.getItem("session");

    if (session) {
      try {
        await UseSession(session);
      } catch {
        localStorage.removeItem("session");
      }
    }

    console.log(session);
  }
};
