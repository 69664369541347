import React from "react";
import { useParams } from "react-router-dom";
import { UseGetGames } from "../hooks/useGame";
import "./gameOverview.scss";

export interface IGame {
  id: number;
  attributes: {
    player_1: {
      old_rating: number;
      new_rating: number;
      score: number;
      name: string;
    };
    player_2: {
      old_rating: number;
      new_rating: number;
      score: number;
      name: string;
    };
  };
}

export const GameOverview: React.FC = () => {
  const [games, setGames] = React.useState<IGame[]>([]);

  const { id } = useParams()

  React.useEffect(() => {
    getPlayers();
  }, []);

  return (
    <div className="game-overview-container">
      <div className="game-overview-table">
        <div className="table-header">
          <div className="table-column">Name</div>
          <div className="table-column">Points</div>
          <div className="table-column">score</div>
          <div className="table-column">Points</div>
          <div className="table-column">Name</div>
        </div>

        {games.map((game, key) => {
          return (
            <div className="table-row" key={key}>
              <div className="table-column">
                {game.attributes.player_1.name}
              </div>
              <div className="table-column">
                {game.attributes.player_1.new_rating - game.attributes.player_1.old_rating}
              </div>
              <div className="table-column">
                {game.attributes.player_1.score} -{" "}
                {game.attributes.player_2.score}
              </div>
              <div className="table-column">
                {game.attributes.player_2.new_rating - game.attributes.player_2.old_rating}
              </div>
              <div className="table-column">
                {game.attributes.player_2.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  async function getPlayers() {
    const data: { data: IGame[] } = await UseGetGames(id);

    setGames(data.data);
  }
};
