import React from "react";
import { UseLogin } from "../hooks/useLogin";
import { UsePlayers } from "../hooks/usePlayers";
import { UseCreateGame } from "../hooks/useGame";
import { IPlayer } from "../ratingOverview/ratingOverview";
import "./addGame.scss";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";

export const AddGame: React.FC = () => {
  const [player1Score, setPlayer1Score] = React.useState<number>();
  const [player2Score, setPlayer2Score] = React.useState<number>();
  const [player1, setPlayer1] = React.useState<number>();
  const [player2, setPlayer2] = React.useState<number>();

  const [players, setPlayers] = React.useState<IPlayer[]>([]);
  const [session, setSession] = React.useState<string>();
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  const [redirect, setRedirect] = React.useState<boolean>(false);

  const navigate: NavigateFunction = useNavigate();

  React.useEffect(() => {
    getSession();
    getPlayers();
  }, []);

  if (redirect) {
    return <Navigate to="/" replace />;
  }

  if (session) {
    return (
      <div className="add-game-overview-container">
        <button onClick={() => navigate(`/`)}>Terug</button>
        <form
          className="add-game-login"
          onSubmit={(e) => {
            e.preventDefault();
            addGame();
          }}
        >
          <select
            id="player_1"
            name="player_1"
            onChange={(e) => setPlayer1(Number(e.target.value))}
          >
            <option>Make your choice</option>
            {players.map((player, key) => {
              return (
                <option key={key} value={player.id}>
                  {player.attributes.name}
                </option>
              );
            })}
          </select>
          <input
            type="number"
            min={0}
            value={player1Score}
            onChange={(e) => setPlayer1Score(Number(e.target.value))}
            placeholder="Score"
          />
          <select
            id="player_2"
            name="player_2"
            onChange={(e) => setPlayer2(Number(e.target.value))}
          >
            <option>Make your choice</option>
            {players.map((player, key) => {
              return (
                <option key={key} value={player.id}>
                  {player.attributes.name}
                </option>
              );
            })}
          </select>
          <input
            type="number"
            min={0}
            value={player2Score}
            onChange={(e) => setPlayer2Score(Number(e.target.value))}
            placeholder="Score"
          />

          <input type="submit" value="Add game" />
        </form>
      </div>
    );
  }

  return (
    <div className="add-game-overview-container">
      <button onClick={() => navigate(`/`)}>Terug</button>
      <form
        className="add-game-login"
        onSubmit={(e) => {
          e.preventDefault();
          login();
        }}
      >
        <input
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="password"
        />

        <input type="submit" value="Login" />
      </form>
    </div>
  );

  function getSession() {
    const localSession = localStorage.getItem("session");

    if (localSession) {
      setSession(localSession);
    }
  }

  async function login() {
    const result = await UseLogin(email, password);
    setSession(result.data.attributes.key);
    localStorage.setItem("session", result.data.attributes.key);
  }

  async function addGame() {
    if (player1 === player2) {
      return;
    }

    const data = {
      data: {
        type: "Game",
        attributes: {
          player_1_points: player1Score,
          player_2_points: player2Score,
        },
        relationships: {
          player_1: {
            data: {
              id: player1,
              type: "Player",
            },
          },
          player_2: {
            data: {
              id: player2,
              type: "Player",
            },
          },
        },
      },
    };
    if (session) {
      const game = await UseCreateGame(data, session);

      if (game.data.id) {
        setRedirect(true);
      }
    }
  }

  async function getPlayers() {
    const data: { data: IPlayer[] } = await UsePlayers();

    setPlayers(data.data);
  }
};
