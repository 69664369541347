import React from "react";
import { Link, Navigate } from "react-router-dom";
import { UsePlayers } from "../hooks/usePlayers";
import "./ratingOverview.scss";

export interface IPlayer {
  id: number;
  attributes: {
    name: string;
    rating: number;
    rating_change: number;
  };
}

export const RatingOverview: React.FC = () => {
  const [players, setPlayers] = React.useState<IPlayer[]>([]);
  const [addGame, setAddGame] = React.useState<boolean>(false);
  

  React.useEffect(() => {
    getPlayers();
  }, []);

  if (addGame) {
    return <Navigate to="/add-game" replace />;
  }

  return (
    <div className="rating-overview-container">
      <button onClick={() => setAddGame(true)}>Add game</button>
      <div className="rating-overview-table">
        <div className="table-header">
          <div className="table-column position-column">Position</div>
          <div className="table-column">Name</div>
          <div className="table-column">Rating</div>
          <div className="table-column">Rating change</div>
        </div>

        {players.map((player, key) => {
          return (
            <Link to={'/games/' + player.id} className="table-row" key={key}>
              <div className="table-column position-column">{key + 1}</div>
              <div className="table-column">{player.attributes.name}</div>
              <div className="table-column">{player.attributes.rating}</div>
              <div className="table-column">
                {player.attributes.rating_change > 0
                  ? "+" + player.attributes.rating_change
                  : player.attributes.rating_change}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );

  async function getPlayers() {
    const data: { data: IPlayer[] } = await UsePlayers();

    setPlayers(data.data);
  }
};
