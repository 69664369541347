import { BASE_URL } from "../settings/env";
import fetchData, { IApiOptions } from "./fetchData";

export async function UseCreateGame(body: {}, session: string) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + session,
    },
    body: JSON.stringify(body),
  };
  const request_result = await fetch(
    BASE_URL + "v1/table-tennis/games/",
    options
  );

  return request_result.json();
}

export async function UseGetGames(id?: string) {
  const options: IApiOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let url: string = BASE_URL + "v1/table-tennis/games/?page[size]=25&page[number]=1&sort=-id"

  if (id) {
    console.log('here')
    url += '&filter[player_id]=' + id
  }

  return await fetchData(
    url,
    options
  );
}
